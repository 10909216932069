require('./bootstrap');

window.Vue = require('vue');

import store from "./store"
//import Vuex from "vuex"

import vSelect from "vue-select";

Vue.component("v-select", vSelect);



Vue.component("appcontainer", () => import("./components/AppContainer"));

/*
Vue.component("calculator", () => import("./components/Calculator"));
Vue.component("login", () => import("./components/Login"));
Vue.component("navbar", () => import("./components/Navbar"));
*/

const app = new Vue({
	//vuex: Vuex,
	store,
	el: '#app',
	
});