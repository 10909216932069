


const state = {
	user: {
		email: "test_haendler@intermenue.de",
		id:0
	},
	zones: {
		
	},
	zonesdisabled: {

	},
	expanse: {

	},
	extras: {

	},
	loggedIn: false,
	errors: {}
};
const getters = {};
const actions = {
	getUser( {commit} ){
		axios.get("/api/calc/current")
		.then( response => {
			commit('setUser', response.data );
		})
	},
	getZones( {commit} ){
		axios.get("/api/calc/zones")
		.then( response => {
			var zonesArray = response.data.zones;
			var iterator = zonesArray.values();

			var zones2 = Array();
			var zones3 = Array();
			for (let letter of iterator) {
			  //console.log(letter["Bezeichner"]);
			  zones2.push(letter["bezeichner"]);
			  zones3.push(letter["tabelle"]);
			}
			
			//console.log(zones2);
			commit('setZones', zones2);
			commit('setZonesdisabled', zones3);
		})
	},
	getAllZones( {commit} ){
		axios.get("/api/calc/allzones")
		.then( response => {
			var zonesArray = response.data.zones;
			var iterator = zonesArray.values();
			var zones2 = Array();
			var zones3 = Array();
			for (let letter of iterator) {
			  //console.log(letter["Bezeichner"]);
			  if(letter.tabelle == 1){letter.tabelle = true;}
			  else{letter.tabelle = false;}
			  	zones2.push(letter);
			  
			}
			
			console.log(zones2);
			commit('setloggedIn',false);
			commit('setZones', zones2);
			commit('setZonesdisabled', zones3);
		})
	},
	loginUser( {commit}, user){
		axios.post("/api/calc/login", {
			email: user.email,
			password: user.password
		})
		.then( response => {
			if( response.data.access_token ){
				// save token
				localStorage.setItem(
					"calc_token",
					response.data.access_token
				);
				//commit('setZones', response.data.zones);
				//console.log(response.data.zones);
				commit('setloggedIn',true);
				window.location.replace("/")
			}
		})
	},
	calculate( {commit}, order){
		axios.post("/api/calc/gettarif", {
			length: order.length,
		  	width: order.width,
		  	height: order.height,
		  	weight: order.weight,
		  	zone: order.zone,
		  	new: order.new,
		  	value: order.value,
		  	noinsurance: order.noinsurance,
		})
		.then(response => { 
			console.log(response);
			if( response.data.order){
				console.log('response.data.order: '+response.data.order);
				commit('setExpanse',response.data.order);
			}
		})
		.catch(error => {
		    console.log(error.response)
		})
		

	},
	allcalculate( {commit}, order){
		axios.post("/api/calc/allgettarif", {
			length: order.length,
		  	width: order.width,
		  	height: order.height,
		  	weight: order.weight,

		  	p1length: order.p1length,
			p1width: order.p1width,
	  		p1height: order.p1height,
	  		p1weight: order.p1weight,
		
			p2: order.p2,
			p2length: order.p2length,
			p2width: order.p2width,
	  		p2height: order.p2height,
	  		p2weight: order.p2weight,
		
			p3: order.p3,
			p3length: order.p3length,
			p3width: order.p3width,
	  		p3height: order.p3height,
	  		p3weight: order.p3weight,
		
			p4: order.p4,
			p4length: order.p4length,
			p4width: order.p4width,
	  		p4height: order.p4height,
	  		p4weight: order.p4weight,
		
			p5: order.p5,
			p5length: order.p5length,
			p5width: order.p5width,
	  		p5height: order.p5height,
	  		p5weight: order.p5weight,
	  		
		  	zone: order.zone,
		  	new: order.new,
		  	value: order.value,
		  	noinsurance: order.noinsurance,
		})
		.then(response => { 
			console.log(response);
			if( response.data.data.order){
				console.log('response.data.data.order: '+response.data.data.order);
				commit('setExpanse',response.data.data.order);
			}
		})
		.catch(error => {
		    console.log(error.response)
		})
		

	},
		calculategerman( {commit}, order){
		axios.post("/api/calc/gettarifgerman", {
		
			p1length: order.p1length,
			p1width: order.p1width,
	  		p1height: order.p1height,
	  		p1weight: order.p1weight,
		
			p2: order.p2,
			p2length: order.p2length,
			p2width: order.p2width,
	  		p2height: order.p2height,
	  		p2weight: order.p2weight,
		
			p3: order.p3,
			p3length: order.p3length,
			p3width: order.p3width,
	  		p3height: order.p3height,
	  		p3weight: order.p3weight,
		
			p4: order.p4,
			p4length: order.p4length,
			p4width: order.p4width,
	  		p4height: order.p4height,
	  		p4weight: order.p4weight,
		
			p5: order.p5,
			p5length: order.p5length,
			p5width: order.p5width,
	  		p5height: order.p5height,
	  		p5weight: order.p5weight,
			
		  	extranachnahme: order.extranachnahme,
			extraprivathaushalt: order.extraprivathaushalt,
			extrainselzuschlag: order.extrainselzuschlag,
			extraavis: order.extraavis,
			extrarechnungmail: order.extrarechnungmail,
			extraexpress: order.extraexpress,
			palettiert: order.palettiert,
			plzstart: order.plzstart,
			plzstop: order.plzstop,

		  	zone: order.zone,
		  	new: order.new,
		  	value: order.value,
		  	noinsurance: order.noinsurance,
		})
		.then(response => { 
			console.log(response);
			if( response.data.order){
				console.log('response.data.order: '+response.data.order);
				commit('setExpanse',response.data.order);
			}
		})
		.catch(error => {
		    console.log(error.response)
		})
		

	},
	allcalculategerman( {commit}, order){
		axios.post("/api/calc/allgettarifgerman", {
			p1length: order.p1length,
			p1width: order.p1width,
	  		p1height: order.p1height,
	  		p1weight: order.p1weight,
		
			p2: order.p2,
			p2length: order.p2length,
			p2width: order.p2width,
	  		p2height: order.p2height,
	  		p2weight: order.p2weight,
		
			p3: order.p3,
			p3length: order.p3length,
			p3width: order.p3width,
	  		p3height: order.p3height,
	  		p3weight: order.p3weight,
		
			p4: order.p4,
			p4length: order.p4length,
			p4width: order.p4width,
	  		p4height: order.p4height,
	  		p4weight: order.p4weight,
		
			p5: order.p5,
			p5length: order.p5length,
			p5width: order.p5width,
	  		p5height: order.p5height,
	  		p5weight: order.p5weight,
			
		  	extranachnahme: order.extranachnahme,
			extraprivathaushalt: order.extraprivathaushalt,
			//extrainselzuschlag: order.extrainselzuschlag,
			extraavis: order.extraavis,
			extraavistype: order.extraavistype,
			extraaviscontact: order.extraaviscontact,
			extrarechnungmail: order.extrarechnungmail,
			extraexpress: order.extraexpress,
			palettiert: order.palettiert,
			plzstart: order.plzstart,
			plzstop: order.plzstop,

		  	zone: order.zone,
		  	new: order.new,
		  	value: order.value,
		  	noinsurance: order.noinsurance,
		})
		.then(response => { 
			console.log(response);
			if( response.data.order){
				console.log('response.data.order: '+response.data.order);
				commit('setExpanse',response.data.order);
			}
		})
		.catch(error => {
		    console.log(error.response)
		})
		

	},
	logoutUser( {commit} ){
		// remove token
		localStorage.removeItem("calc_token");
		commit('setloggedIn',false);
		window.location.replace("/");
	},
	
	getextras( {commit} ){
		axios.get("/api/calc/extras")
		.then( response => {
			commit('setExtras', response.data);
			console.log(response.data);
		})
	},

	order( {commit}, all){
		console.log('all:'+all);
		axios.post("/api/calc/order", {
		
			expanse:all.expanse,
			order:all.order,
		})
		.then(response => { 
			console.log(response);
			
		})
		.catch(error => {
		    console.log(error.response)
		})
		

	},
};

const mutations = {
	setUser( state, data) {
		state.user = data;
	},
	setZones( state, data2) {
		state.zones = data2;
	},
	setZonesdisabled( state, data3) {
		state.zonesdisabled = data3;
	},
	setExpanse( state, expanse) {
		state.expanse = expanse;
	},
	setErrors( state, errors) {
		state.errors = errors;
	},
	setExtras( state, extras) {
		state.extras = extras;
	},
	setloggedIn( state, loggedIn) {
		state.loggedIn = loggedIn;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}